import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { FileTableNamesListModel } from 'src/models/createChart.model';
@Component({
  selector: 'app-table-name-choosing',
  templateUrl: './table-name-choosing.component.html',
  styleUrls: ['./table-name-choosing.component.css']
})
export class TableNameChoosingComponent implements OnInit {
tableData: FileTableNamesListModel[] = [];
selectedTableIndex: number;
  renderMessage: boolean=false;
 
  constructor( public dialogRef: MatDialogRef<TableNameChoosingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FileTableNamesListModel[],public matSnackBar: MatSnackBar) {
      this.tableData = data;
      console.log(this.tableData);
      this.tableData = this.tableData.filter(x => x.user_created_name != "");
      console.log(this.tableData);
     }
  ngOnInit() {
  }
  selectedTable(ind: number) {
    this.selectedTableIndex = ind;
  }
  selectTable() {
    if(this.selectedTableIndex || this.selectedTableIndex == 0){
    this.dialogRef.close(this.tableData[this.selectedTableIndex]);
    }
    else{
      this.matSnackBar.open('Please select a file.', 'OK', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      });
    }
  }
  closeModal(){
    this.dialogRef.close();
  }
}
