import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GetSummaryForCombinedSegmentsReqModel, SaveCombineCrossTabJsonRequest } from 'src/models/ingenCustomer.model';
import { savedCombinedSegments } from 'src/models/segmentedFiles.model';import { UsersService } from './users.service';@Injectable({
  providedIn: 'root'
})
export class IngenCustomerService {
  combinedSegmentData: savedCombinedSegments;
  constructor(private http: HttpClient, private userService: UsersService) { }
  getSetFilesList(segmentType, userFileUploadId) {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_set_file?user_login_id=' + this.userService.getUserId() + '&org_id=' + this.userService.getOrgId() + '&segmentation_type=' + segmentType + '&project_id=' + this.userService.getProjectId()+'&ufuid=' + userFileUploadId)
      .pipe(map((response: any) => response));
  }
  getSalesFile(datasetsType: string) {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_transform_sales_data?user_login_id=' + this.userService.getUserId() + '&org_id=' + this.userService.getOrgId() + '&project_id=' + this.userService.getProjectId() + '&required_datasets=' + datasetsType)
      .pipe(map((response: any) => response));
  }
  getFileProductClass(fileId) {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_file_product_classes?user_file_upload_id=' + fileId)
      .pipe(map((response: any) => response));
  }
  setFile(fileId, segmentType) {
    return this.http.post(environment.PYTHON_API_ENDPOINT + 'set_file?user_file_upload_id=' + fileId + '&user_login_id=' + this.userService.getUserId() + '&org_id=' + this.userService.getOrgId() + '&segmentation_type=' + segmentType + '&project_id=' + this.userService.getProjectId(), '')
      .pipe(map((response: any) => response));
  }
  updateSetFile(fileId, segmentType) {
    return this.http.put(environment.PYTHON_API_ENDPOINT + 'update_set_file?user_file_upload_id=' + fileId + '&user_login_id=' + this.userService.getUserId() + '&org_id=' + this.userService.getOrgId() + '&segmentation_type=' + segmentType + '&project_id=' + this.userService.getProjectId(), '')
      .pipe(map((response: any) => response));
  }
  marketDominantSegment(fileId, classes) {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'market_dominat_segmentation?product_class_names=' + classes + '&user_file_upload_id=' + fileId + '&user_login_id=' + this.userService.getUserId() + '&org_id=' + this.userService.getOrgId() + '&project_id=' + this.userService.getProjectId())
      .pipe(map((response: any) => response));
  }
  dominantSegProductClasses(fileId) {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_file_dominant_seg_product_classes?user_login_id=' + this.userService.getUserId() + '&org_id=' + this.userService.getOrgId() + '&set_file_id=' + fileId + '&project_id=' + this.userService.getProjectId())
      .pipe(map((response: any) => response));
  }
  getTableSummary(className, fileId) {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_summary_table?product_class_name=' + className + '&user_file_upload_id=' + fileId + '&project_id=' + this.userService.getProjectId())
      .pipe(map((response: any) => response));
  }
  getUpdatedTableSummary(className, fileId) {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_updated_dominant_seg_summary_table?product_class_name=' + className + '&user_file_upload_id=' + fileId + '&project_id=' + this.userService.getProjectId())
      .pipe(map((response: any) => response));
  }
  getCustomerLabels(className, fileId) {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_customer_label_table?product_class_name=' + className + '&user_file_upload_id=' + fileId + '&user_login_id=' + this.userService.getUserId() + '&org_id=' + this.userService.getOrgId() + '&project_id=' + this.userService.getProjectId())
      .pipe(map((response: any) => response));
  }
  saveSegment(fileId, className) {
    return this.http.post(environment.PYTHON_API_ENDPOINT + 'change_save_status?user_file_upload_id=' + fileId + '&user_login_id=' + this.userService.getUserId() + '&org_id=' + this.userService.getOrgId() + '&product_class_name=' + className + '&project_id=' + this.userService.getProjectId(), '')
      .pipe(map((response: any) => response));
  }
  saveUpdatedSegment(fileId, className, segType) {
    return this.http.post(environment.PYTHON_API_ENDPOINT + 'save_updated_segments?product_or_class_name=' + className + '&user_file_upload_id=' + fileId + '&user_login_id=' + this.userService.getUserId() + '&org_id=' + this.userService.getOrgId() + '&segmentation_type=' + segType + '&project_id=' + this.userService.getProjectId(), '')
      .pipe(map((response: any) => response));
  }
  getSavedDominantClasses() {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_saved_dominant_seg_product_classes?user_login_id=' + this.userService.getUserId() + '&org_id=' + this.userService.getOrgId() + '&project_id=' + this.userService.getProjectId())
      .pipe(map((response: any) => response));
  }
  getDominantSegChartsJson(className, fileId, status) {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_dominant_seg_charts_json?product_class_name=' + className + '&user_login_id=' + this.userService.getUserId() + '&org_id=' + this.userService.getOrgId() + '&user_file_upload_id=' + fileId + '&table_type=' + status + '&project_id=' + this.userService.getProjectId())
      .pipe(map((response: any) => response));
  }
  getFileProducts(fileId) {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_file_products?user_file_upload_id=' + fileId)
      .pipe(map((response: any) => response));
  }
  trendSegment(fileId, products, selectedTrend, timevar) {  
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'trend_segmentation?products=' + products + '&user_file_upload_id=' + fileId + '&user_login_id=' + this.userService.getUserId() + '&org_id=' + this.userService.getOrgId() + '&project_id=' + this.userService.getProjectId() +'&trend_type='+selectedTrend +'&time_var='+ timevar).pipe(map((response: any) => response))  ;  }
  quntaileOrDecileSeg(fileId: number, classes: string, products: string, segType: string) {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'decile_quantile_segmentation?products=' + products + '&classes=' + classes + '&user_file_upload_id=' + fileId + '&user_login_id=' + this.userService.getUserId() + '&org_id=' + this.userService.getOrgId() + '&segmentation_type=' + segType + '&project_id=' + this.userService.getProjectId()).pipe(map((response: any) => response));
  }
  getTrendSegTableSummary(productName, fileId, activeLink, segType,trendType, timeVar) {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_summary_table_trend_seg?user_login_id=' + this.userService.getUserId() + '&org_id=' + this.userService.getOrgId() + '&product=' + productName + '&user_file_upload_id=' + fileId + '&segmentation_type=' + segType + '&table_type=' + activeLink + '&project_id=' + this.userService.getProjectId() + '&trend_type='+ trendType + '&time_var=' + timeVar)
      .pipe(map((response: any) => response));
  }
  getTredSegCustomerLabels(productName, fileId, segType, trendType, timeVar) {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_customer_label_table_trend_seg?user_login_id=' + this.userService.getUserId() + '&org_id=' + this.userService.getOrgId() + '&product=' + productName + '&user_file_upload_id=' + fileId + '&segmentation_type=' + segType + '&project_id=' + this.userService.getProjectId() + '&trend_type='+ trendType + '&time_var=' + timeVar)
      .pipe(map((response: any) => response));
  }
  getTrendSegChartsJson(productName, fileId, activeLink, trendType, timeVar) {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_trend_seg_charts_json?product=' + productName + '&user_login_id=' + this.userService.getUserId() + '&org_id=' + this.userService.getOrgId() + '&user_file_upload_id=' + fileId + '&table_type=' + activeLink + '&project_id=' + this.userService.getProjectId()+ '&trend_type='+ trendType + '&time_var=' + timeVar)
      .pipe(map((response: any) => response));
  }
  // getVolumeTrendSegmentation(productName, fileId, timevar){
  //   return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_volume_trend_segmentation?product='+productName + '&user_login_id=' + this.userService.getUserId() + '&org_id=' + this.userService.getOrgId() + '&user_file_upload_id=' + fileId + '&project_id=' + this.userService.getProjectId() + '&time_var=' + timevar)
  //   .pipe(map((response:any) => response));
  // }
  getDecileQuantileCharts(productName, fileId, activeLink, segType) {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_decile_quantile_seg_charts_json?product=' + productName + '&user_login_id=' + this.userService.getUserId() + '&org_id=' + this.userService.getOrgId() + '&user_file_upload_id=' + fileId + '&table_type=' + activeLink + '&segmentation_type=' + segType + '&table_type=' + activeLink + '&project_id=' + this.userService.getProjectId())
      .pipe(map((response: any) => response));
  }

  getAnalysisPerformedDatasets(segType){
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_datasets_on_which_analysis_performed?user_login_id=' + this.userService.getUserId() + '&project_id=' + this.userService.getProjectId() + '&segmentation_type=' + segType + '&status=true')
    .pipe(map((responce:any) => responce));
  }
  saveTrendSegment(fileId, productName, segType, trendType, timeVar) {
    return this.http.put(environment.PYTHON_API_ENDPOINT + 'change_save_status_trend_seg?user_login_id=' + this.userService.getUserId() + '&org_id=' + this.userService.getOrgId() + '&product=' + productName + '&user_file_upload_id=' + fileId + '&segmentation_type=' + segType + '&project_id=' + this.userService.getProjectId() + '&trend_type='+ trendType + '&time_var=' + timeVar, '')
      .pipe(map((response: any) => response));  }
  // getSavedTrendProducts(segType) {
  //   return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_saved_trend_seg_products?user_login_id=' + this.userService.getUserId() + '&org_id=' + this.userService.getOrgId() + '&segmentation_type=' + segType + '&project_id=' + this.userService.getProjectId())
  //     .pipe(map((response: any) => response));
  // }

   getSavedTrendProducts(segType, userFileUploadId) {  
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_saved_trend_seg_products?project_id=' + this.userService.getProjectId() + '&user_file_upload_id=' + userFileUploadId + '&segmentation_type=' + segType+ '&trend_type=' + '' + '&time_var=' + '')
      .pipe(map((response: any) => response));
  } 
  getSavedTrendProductsDetails(segType, userFileUploadId, trendType, timeVar) {  
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_saved_trend_seg_products?project_id=' + this.userService.getProjectId() + '&user_file_upload_id=' + userFileUploadId + '&segmentation_type=' + segType + '&trend_type=' + trendType + '&time_var=' + timeVar)
      .pipe(map((response: any) => response));
  }   

  getSavedDominantProducts(userFileUploadId){
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_saved_dominant_seg_product_classes?user_login_id=' + this.userService.getUserId() + '&org_id=' + this.userService.getOrgId() + '&project_id=' + this.userService.getProjectId() + '&user_file_upload_id=' + userFileUploadId)
    .pipe(map((response: any) => response));
  }
  trendSegProducts(fileId, segType, trendType, timeVar) {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_file_trend_seg_products?user_login_id=' + this.userService.getUserId() + '&org_id=' + this.userService.getOrgId() + '&user_file_upload_id=' + fileId + '&segmentation_type=' + segType + '&project_id=' + this.userService.getProjectId()+ '&trend_type=' + trendType + '&time_var=' + timeVar)
      .pipe(map((response: any) => response));
  }
  changeSalesDataTransform(fileId, mappedMarkets) {
    return this.http.post(environment.PYTHON_API_ENDPOINT + 'sales_transformation_for_class_change', {
      'user_file_upload_id': fileId,
      'user_login_id': this.userService.getUserId(),
      'org_id': this.userService.getOrgId(),
      'prod_class_data': mappedMarkets,
      'project_id': this.userService.getProjectId()
    })
      .pipe(map((response: any) => response));
  }
  getCombinedSegmentsTableDetails() {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_combined_segments_tables_detail?project_id=' + this.userService.getProjectId() + '&user_login_id=' + this.userService.getUserId() + '&org_id=' + this.userService.getOrgId())
      .pipe(map((response: any) => response));
  }
  getSavedCombinedSegments(fileId) {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_file_all_saved_segments_data?user_file_upload_id=' + fileId + '&project_id=' + this.userService.getProjectId() + '&user_login_id=' + this.userService.getUserId() + '&org_id=' + this.userService.getOrgId())
      .pipe(map((response: any) => response));
  }
  combineSegments(fileId, tableName, segments, combineId) {
    return this.http.post(environment.PYTHON_API_ENDPOINT + 'combine_segments', {
      'user_file_upload_id': fileId,
      'project_id': this.userService.getProjectId(),
      'table_name': tableName,
      'user_login_id': this.userService.getUserId(),
      'org_id': this.userService.getOrgId(),
      'combine_segments_id': combineId,
      'data': segments    })
      .pipe(map((response: any) => response));
  }
  deleteCombinedSegment(id: number) {
    return this.http.delete(environment.PYTHON_API_ENDPOINT + 'delete_combined_segments_table?user_login_id=' + this.userService.getUserId() + '&org_id=' + this.userService.getOrgId() + '&project_id=' + this.userService.getProjectId() + '&combine_segments_id=' + id)
      .pipe(map((response: any) => response));
  }
  getCombinedSegmentsTableMetaData(id) {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_combined_segments_table_metadata?combine_segments_table_id=' + id)
      .pipe(map((response: any) => response));
  }
  getCrossTableJson(data, fileId, name) {
    // return this.http.post(environment.PYTHON_API_ENDPOINT + 'combine_segment_json', {
    //   'user_file_upload_id': fileId,
    //   'project_id': this.userService.getProjectId(),
    //   'user_login_id': this.userService.getUserId(),
    //   'org_id': this.userService.getOrgId(),
    //   'data': data,
    //   'name':name
    // })
    //   .pipe(map((response: any) => response));

    let params: any = {
      'user_file_upload_id': fileId,
      'project_id': this.userService.getProjectId(),
      'user_login_id': this.userService.getUserId(),
      'org_id': this.userService.getOrgId(),
      'data': data
    };
    if (name !== '') {
      params.name = name;
    }
    return this.http.post(environment.PYTHON_API_ENDPOINT + 'combine_segment_json', params)
      .pipe(
        map((response: any) => response)
      );
  }  

  saveCombineCrossTableJson(requestJson: SaveCombineCrossTabJsonRequest) {
    return this.http.post(environment.PYTHON_API_ENDPOINT + 'save_combined_segments_crosstab_json', requestJson)
      .pipe(map((response: any) => response));
  }
  getAllCombinedSegments() {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_combined_segments_metadata?project_id=' + this.userService.getProjectId() + '&user_login_id=' + this.userService.getUserId() + '&org_id=' + this.userService.getOrgId())
      .pipe(map((response: any) => response));
  }
  getSavedCombinedSegmentJson(id: number) {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_combined_segments_crosstab_json?project_id=' + this.userService.getProjectId() + '&user_login_id=' + this.userService.getUserId() + '&org_id=' + this.userService.getOrgId() + '&id=' + id)
      .pipe(map((response: any) => response));
  }
  deleteCombinedCrossTab(id: number) {
    return this.http.delete(environment.PYTHON_API_ENDPOINT + 'delete_combined_segments_metadata?project_id=' + this.userService.getProjectId() + '&user_login_id=' + this.userService.getUserId() + '&org_id=' + this.userService.getOrgId() + '&id=' + id)
      .pipe(map((response: any) => response));
  }

  deleteCustomOrCompareSegmentation(id:number,metadata_id:number){
    return this.http.delete(environment.PYTHON_API_ENDPOINT + 'delete_custom_or_compare_segmentation?user_login_id=' + this.userService.getUserId()+ '&user_file_upload_id=' +id + '&project_id=' + this.userService.getProjectId()+'&metadata_id='+metadata_id )
    .pipe(map((response: any) => response));
  }

  deleteCustomSegmentViewExisting(userFileUploadId:number, customSegComparisonID:number, compSegName:number){
    return this.http.delete(environment.PYTHON_API_ENDPOINT + 'delete_custom_or_compare_segmentation?user_login_id=' + this.userService.getUserId()+ '&user_file_upload_id=' +userFileUploadId + '&project_id=' + this.userService.getProjectId()+'&custom_segments_comparison_id=' + customSegComparisonID + '&compared_seg_name='+ compSegName)
    .pipe(map((response: any) => response));
  }
  

  deleteDecileQuartileTrendDominantSeg(userFileUploadID:number, productOrClass:string, segmentationType:string,  segmentation:string){
    return this.http.delete(environment.PYTHON_API_ENDPOINT + 'delete_saved_segmentations?user_file_upload_id=' + userFileUploadID + '&product_or_class=' + productOrClass +'&segmentation_type=' + segmentationType + '&project_id=' + this.userService.getProjectId() + '&user_login_id=' + this.userService.getUserId() + '&segmentation=' + segmentation)
    .pipe(map((response: any) => response));
  }
  
  getCombinedSegmentsSummary(requestJson: GetSummaryForCombinedSegmentsReqModel) {
    return this.http.post(environment.PYTHON_API_ENDPOINT + 'get_combined_segment_summary', requestJson)
      .pipe(map((response: any) => response));
  }
  getCustomSegmentFilterData(tableName: string, schemaName: number, customFormulas: any, index: number) {
    return this.http.post(environment.PYTHON_API_ENDPOINT + 'get_custom_segment_filtered_data', {
      'input_table_name': tableName,
      'input_json': customFormulas,
      'schema': schemaName,
      'start': index
    })
      .pipe(map((response: any) => response));
  }
  saveCustomSegment(tableName: string, schemaName: number, customFormulas: any, customSegmentName: string, fileId: number, fileName: string, segmentId: number) {
    return this.http.post(environment.PYTHON_API_ENDPOINT + 'save_custom_segment', {
      'input_table_name': tableName,
      'input_json': customFormulas,
      'schema': schemaName,
      'custom_segment_name': customSegmentName,
      'user_file_upload_id': fileId,
      'filename': fileName,
      'user_login_id': this.userService.getUserId(),
      'org_id': this.userService.getOrgId(),
      'project_id': this.userService.getProjectId(),
      'metadata_id': segmentId
    })
      .pipe(map((response: any) => response));
  }
  getSavedCustomSegments() {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_custom_segments_of_project?project_id=' + this.userService.getProjectId() + '&org_id=' + this.userService.getOrgId())
    .pipe(map((response: any) => response));
  }
  getCustomSegmentDetails(fileId: number, metadataId: number) {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_specific_custom_segment_data?project_id=' + this.userService.getProjectId() + '&org_id=' + this.userService.getOrgId() + '&user_file_upload_id=' + fileId + '&metadata_id=' + metadataId)
    .pipe(map((response: any) => response));
  }
  getCustomSegmentFilteredData(inputJson) {
    return this.http.post(environment.PYTHON_API_ENDPOINT + 'get_custom_seg_comparison_preview', inputJson)
    .pipe(map((response: any) => response));
  }
  saveCustomSegmentFilteredData(inputJson, tableName: string, id: number) {
    return this.http.post(environment.PYTHON_API_ENDPOINT + 'save_custom_segment_comparison', {
      'input_json': inputJson,
      'comapared_custom_segment_name': tableName,
      'project_id': this.userService.getProjectId(),
      'org_id': this.userService.getOrgId(),
      'user_login_id': this.userService.getUserId(),
      'custom_segments_comparison_id': id
    })
    .pipe(map((response: any) => response));
  }
  getSavedComparedCustomSegmentsList() {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_compared_custom_segments_of_project?project_id=' + this.userService.getProjectId() + '&org_id=' + this.userService.getOrgId())
    .pipe(map((response: any) => response));
  }
  getComparedCustomSegmentDataById(id: number) {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_specific_compared_custom_segment_data?project_id=' + this.userService.getProjectId() + '&org_id=' + this.userService.getOrgId() + '&custom_segments_comparison_id=' + id)
    .pipe(map((response: any) => response));
  }
  getCustomComparisonTableSummary(tableName: string, schema: string) {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_summary_from_any_table?org_id=' + this.userService.getOrgId() + '&table_name=' + tableName + '&schema=' + schema)
    .pipe(map((response: any) => response));
  }
  updateSegmentName(fileId: number, productOrClass: string, segmentType: string, currentSegmentName: string, newsegmentName: string, summaryType: string, selectedTrend:string, timevar:string) {
    return this.http.put(environment.PYTHON_API_ENDPOINT + 'change_segment_name?user_login_id=' + this.userService.getUserId() + '&org_id=' + this.userService.getOrgId() + '&project_id=' + this.userService.getProjectId() + '&user_file_upload_id=' + fileId + '&segmentation_type=' + segmentType + '&product_or_class=' + productOrClass + '&current_seg_name=' + currentSegmentName + '&new_seg_name=' + newsegmentName + '&summary_type=' + summaryType +'&trend_type='+selectedTrend +'&time_var='+ timevar, '')
    .pipe(map((response: any) => response));
  }getStandardFile(userId, orgId) {
  return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_standard_files?user_login_id=' + userId + '&org_id=' + orgId)
    .pipe(map((response: any) => response));
}
getStandardizeTableData(tableName, jobTitle, index) {
  return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_report?table_name=' + tableName + '&column_name=' + jobTitle + '&start=' + index)
    .pipe(map((response: any) => response));
}
downloadFile(fileId) {
  return this.http.get('https://bb1fb959c39e.ngrok.io/download_dataset_csv?user_file_upload_id=2104')
    .pipe(map((response: any) => response));
}
getSampleTableData(tableName, index, schemaName) {
  return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_table_data?table_name=' + tableName + '&schema=' + schemaName + '&start=' + index)
    .pipe(map((response: any) => response));
}
getFileDetails(fileId) {
  return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_file_details?user_file_upload_id=' + fileId)
    .pipe(map((response: any) => response));
}
inserUserInput(inputTitle, userInput, tableName, jobColumn) {
  return this.http.post(environment.PYTHON_API_ENDPOINT + 'insertuserinput?job_title_value=' + inputTitle + '&user_input=' + userInput + '&tablename=' + tableName + '&jobtitle_col=' + jobColumn, '')
    .pipe(map((response: any) => response));
}
getUniqueValues(tableName) {
  return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_unique_values?table_name=' + tableName)
    .pipe(map((response: any) => response));}
getUniqueStandardData(tableName, jobTitle, filterTitle, index) {
  return this.http.get(environment.PYTHON_API_ENDPOINT + 'filtereddata?table_name=' + tableName + '&column_name=' + jobTitle + '&start=' + index + '&column_value=' + filterTitle)
    .pipe(map((response: any) => response));
}
getFileCoumns(fileId) {
  return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_file_details?user_file_upload_id=' + fileId)
    .pipe(map((response: any) => response));}
matching(fileId, column) {
  return this.http.post(environment.PYTHON_API_ENDPOINT + 'matching?user_file_upload_id=' + fileId + '&column_name=' + column, '')
    .pipe(map((response: any) => response));}
addressStandardization(data) {
  return this.http.post(environment.PYTHON_API_ENDPOINT + 'addressstandardization', data)
    .pipe(map((response: any) => response));
}
getAddressStandardizationReport(tableName, columns, fileId, index) {
  return this.http.get(environment.PYTHON_API_ENDPOINT + 'address_standardization_report?column_names=' + columns + '&user_file_upload_id=' + fileId + '&table_name=' + tableName + '&start=' + index)
    .pipe(map((response: any) => response));
}
getFileteredTitles(value) {
  return this.http.get(environment.PYTHON_API_ENDPOINT + 'searchjobtitles?text=' + value, )
    .pipe(map((response: any) => response));
}

saveCustomer360(metaDataId) {
  return this.http.put(environment.PYTHON_API_ENDPOINT + 'save_customer360_segmentation_status?user_login_id='+ this.userService.getUserId() + '&project_id=' + this.userService.getProjectId()+ '&customer360_segmentation_metadata_id=' + metaDataId, {})
}

getSampleCustomer360TableData(tableName, index, schemaName) {
  return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_table_data?table_name=' + tableName + '&schema=' + schemaName + '&start=' + index)
    .pipe(map((response: any) => response));
}

getCutstomer360TableName(userFileUploadID) {
  return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_customer360_tablename?project_id=' + this.userService.getProjectId()+ '&user_file_upload_id='+ userFileUploadID )
}

saveCustomer360SegMetadata(userFileUploadIds, name, metadatID) {
  
  return this.http.put(environment.PYTHON_API_ENDPOINT + 'save_customer360_segmentation_metadata?user_login_id=' + this.userService.getUserId() + '&project_id=' + this.userService.getProjectId() + '&customer360_segmentation_metadata_id=' + metadatID, {
    "ufuid_list" : userFileUploadIds,
    "name" : name
  } )
  .pipe(map((response:any) => response))
}

getCustomer360Segmentation(customerSegMeatadataId, userFileUploadIds) {
  console.log(userFileUploadIds);
  return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_segmentations?user_login_id=' + this.userService.getUserId() + '&project_id=' + this.userService.getProjectId() + '&customer360_segmentation_metadata_id=' + customerSegMeatadataId + '&ufuid_list='+ userFileUploadIds)
}

createCustomer360Seg(json){
  return this.http.post(environment.PYTHON_API_ENDPOINT + 'create_customer360_segmentation_label_tables_data', json);
}

getSavedCustomer360Names(name){
  return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_customer360_segmentation?user_login_id=' + this.userService.getUserId() + '&project_id=' + this.userService.getProjectId() + '&name=' + name)
  .pipe(map((response:any) => response))
}

deleteCustomer360Seg(name) {
  return this.http.delete(environment.PYTHON_API_ENDPOINT + 'delete_customer360_segmentation?user_login_id=' + this.userService.getUserId() + '&project_id=' + this.userService.getProjectId() + '&name=' + name)
  .pipe(map((res:any) => res))
}

getPublishedCustomer360Segmentation(metadataId){
  return this.http.get(environment.PYTHON_API_ENDPOINT + 'get_publish_customer360_segmentation?user_login_id=' + this.userService.getUserId() +'&org_id='+ this.userService.getOrgId()+ '&project_id=' + this.userService.getProjectId() + '&customer360_segmentation_metadata_id='+metadataId)
  .pipe(map((res:any) => res))
}

insertCustomer360DataPublicly(json){
  return this.http.post(environment.PYTHON_API_ENDPOINT + 'insert_customer360_data_publicly', json)
  .pipe(map((res:any) => res))
}

publishCustomer360Segmentation(customerMetadataId, publishStatus){
  return this.http.put(environment.PYTHON_API_ENDPOINT+ 'publish_customer360_segmentation?customer360_segmentation_metadata_id='+ customerMetadataId + '&user_login_id=' + this.userService.getUserId() + '&project_id=' + this.userService.getProjectId() + '&publish=' + publishStatus, {})
  .pipe(map((res:any) => res))
}


}
